import getConfig from 'next/config';

import { isCustomDomain } from 'utils/misc';

export const useLogIn = () => {
  const handleLogIn = (data?: Record<string, any>) => {
    const { orgAcronym, orgName, shortName, eventName, ...rest } = data || {};
    // @ts-ignore
    authEventBus.dispatch('openLoginModal', rest);
  };

  // TODO Should this be publicly available?
  const handleSignUp = () => {
    const { publicRuntimeConfig } = getConfig();
    const host = isCustomDomain() ? publicRuntimeConfig.api_base : '';
    window.location.href = `${host}/request-invite`;
  };

  return {
    handleLogIn,
    handleSignUp,
  };
};

export const setEventBranding = (branding: MorressierEventBranding) => {
  const { id, _id, ...rest } = branding;

  return {
    type: 'setEventBranding',
    payload: rest,
  } as const;
};

export const setEvent = (data: MorressierEvent) =>
  ({
    type: 'setEvent',
    payload: data,
  } as const);

export const setEventTabsConfig = (data: MorressierEventTabsConfiguration) =>
  ({
    type: 'setEventTabsConfig',
    payload: data,
  } as const);

export type EventActions =
  | ReturnType<typeof setEventBranding>
  | ReturnType<typeof setEvent>
  | ReturnType<typeof setEventTabsConfig>;

import { IconType } from 'icons';

export const UserClassReg: React.FC<IconType> = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="users-class"
    className="svg-inline--fa fa-users-class fa-w-20"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
  >
    <path
      fill={props.color}
      d="M80 48h480v133.62c17.64 2.56 34.01 8.91 48 18.71V49.59C608 22.25 586.47 0 560 0H80C53.53 0 32 22.25 32 49.59v150.73c13.99-9.8 30.36-16.15 48-18.71V48zm28 356H84c-46.31 0-84 37.69-84 84 0 13.25 10.75 24 24 24s24-10.75 24-24c0-19.84 16.16-36 36-36h24c19.84 0 36 16.16 36 36 0 13.25 10.75 24 24 24s24-10.75 24-24c0-46.31-37.69-84-84-84zm436-192c-44.18 0-80 35.82-80 80s35.82 80 80 80 80-35.82 80-80-35.82-80-80-80zm0 112c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32zm12 80h-24c-46.31 0-84 37.69-84 84 0 13.25 10.75 24 24 24s24-10.75 24-24c0-19.84 16.16-36 36-36h24c19.84 0 36 16.16 36 36 0 13.25 10.75 24 24 24s24-10.75 24-24c0-46.31-37.69-84-84-84zM96 372c44.18 0 80-35.82 80-80s-35.82-80-80-80-80 35.82-80 80 35.82 80 80 80zm0-112c17.64 0 32 14.36 32 32s-14.36 32-32 32-32-14.36-32-32 14.36-32 32-32zm144 32c0 44.18 35.82 80 80 80s80-35.82 80-80-35.82-80-80-80-80 35.82-80 80zm112 0c0 17.64-14.36 32-32 32s-32-14.36-32-32 14.36-32 32-32 32 14.36 32 32zm-20 112h-24c-46.31 0-84 37.69-84 84 0 13.25 10.75 24 24 24s24-10.75 24-24c0-19.84 16.16-36 36-36h24c19.84 0 36 16.16 36 36 0 13.25 10.75 24 24 24s24-10.75 24-24c0-46.31-37.69-84-84-84z"
    />
  </svg>
);

UserClassReg.defaultProps = {
  color: 'currentColor',
};

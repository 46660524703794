import getConfig from 'next/config';
import Script from 'next/script';

import { logger } from 'utils/logger';

const { publicRuntimeConfig } = getConfig();

export const AuthClientWidget = () => (
  <Script
    src={publicRuntimeConfig.AUTH_APP_SRC}
    strategy="beforeInteractive"
    onReady={() => {
      // @ts-ignore
      logger.debug('AuthClientWidget loaded');
      // @ts-ignore
      window.AuthClientMorressier.init();
    }}
  />
);

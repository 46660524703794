import { setEventBranding, useEventContext } from 'store';
import useSWR, { mutate } from 'swr';

/**
 * this is a temporal hook
 * we expect all pages to fetch event branding info in getStaticProps
 */
export const useEventBranding = (eventId: string | null) => {
  const url = eventId ? `/api/v3/standalone/events/${eventId}/branding` : null;
  const { state, dispatch } = useEventContext();

  // no need to fetch branding info if we already have it in store
  const { data, error } = useSWR<MorressierEventBranding>(state?.brand_theme ? null : url);

  const filterData = () => {
    if (state?.brand_theme) {
      const { brand_theme, banner_url, logo_url } = state;

      return { brand_theme, banner_url, logo_url };
    }

    if (data) {
      const { id, _id, ...rest } = data;

      dispatch(setEventBranding(data));
      return rest;
    }

    return data;
  };

  return {
    data: filterData(),
    refetch: () => mutate(url),
    loading: !data && !error,
  };
};

import getConfig from 'next/config';

import { FeatureFlags } from 'config/featureFlags';
import httpService from 'services/httpService';
import { mockService } from 'services/mock';
import { logger } from 'utils/logger';

import { getQuery } from './utils';

type EventTagsReponse = {
  labels: {
    label: string;
    value: string;
    key: string;
  }[];
  page_size: number;
  page: number;
  total_pages: number;
  total: number;
};

export type ScheduledItemType = 'SESSION' | 'MEETING';

export enum ScheduledItemStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Canceled = 'CANCELED',
  Deleted = 'DELETED',
  Sent = 'SENT',
}

export type ScheduledMeeting = {
  created_at: string;
  date: string;
  description: string;
  endTime: number;
  invitee: string;
  invitee_action: ScheduledItemStatus;
  inviter: string;
  inviter_action: ScheduledItemStatus;
  scheduledItemType: ScheduledItemType;
  startTime: number;
  time: string;
  users_metadata: {
    invitee_profile: MorressierUser;
    inviter_profile: MorressierUser;
  };
  _id: string;
};

export type AgendaFeedResponse = {
  [date: string]: ScheduledMeeting[];
};

// TODO: this is a mock, replace with backend api
export const getTop15Events = async () =>
  mockService.get(
    {
      url: "urlToGetAListOfEventID's",
      data: [{ params: { eventId: '5e6b621e1e3520493e76577e' } }],
    },
    2,
  );

export const fetchEventInfo = async (eventId: string) =>
  httpService
    .get<{
      event: MorressierEvent;
      stats: MorressierEventStats;
    }>({
      url: `/api/v3/standalone/events/${eventId}`,
    })
    .then(r => ({ eventInfo: r.data }));

export const fetchEventTabsConfig = (eventId: string) =>
  httpService
    .get<MorressierEventTabsConfiguration>({ url: `api/v3/discovery/events/${eventId}/tabs` })
    .then(resp => resp.data);

export const fetchPopularSessions = (eventId: string) =>
  httpService
    .get<MorressierSession[]>({
      url: `/api/v3/standalone/events/${eventId}/popular-sessions`,
    })
    .then(r => r.data)
    .catch(e => {
      logger.log(e);
      return null;
    });

export const fetchPopularPosters = (eventId: string) =>
  httpService
    .get<MorressierPresentation[]>({
      url: `/api/v3/standalone/events/${eventId}/popular-posters`,
    })
    .then(r => r.data)
    .catch(e => {
      logger.log(e);
      return null;
    });

export const fetchPopularSpeakers = (eventId: string) =>
  httpService
    .get<EventSpeaker[]>({
      url: `/api/v3/standalone/events/${eventId}/popular-presenters`,
    })
    .then(r => ({ count: r.data.length, speakers: r.data }))
    .catch(e => {
      logger.log(e);
      return null;
    });

export const fetchEventSpeakers = async (eventId: string, limit = 9) =>
  httpService
    .get<{ count: number; speakers: EventSpeaker[] }>({
      url: `/api/v3/standalone/events/${eventId}/speakers?limit=${limit}`,
    })
    .then(r => r.data)
    .catch(e => {
      logger.log(e);
      return null;
    });

export const fetchEventFeaturedSessions = async (eventId: string) =>
  httpService
    .get<{ count: number; sessions: MorressierSession[] }>({
      url: `/api/v3/standalone/events/${eventId}/featured`,
    })
    .then(r => r.data)
    .catch(e => {
      logger.log(e);
      return null;
    });

export const checkTicketingProduct = async (
  productId: string,
  type: 'event' | 'session',
): Promise<TicketingProduct | { ticketEnabled: false }> => {
  const PRODUCT_ACCESS_SERVICE = getConfig().serverRuntimeConfig?.PRODUCT_ACCESS_SERVICE;
  const isTicketingEnabled = FeatureFlags.Event_Ticketing.isEnabled();

  if (isTicketingEnabled) {
    return httpService
      .get<TicketingProduct>({
        url: `${PRODUCT_ACCESS_SERVICE}/products/${productId}/exist?type=${type}`,
      })
      .then(r => r.data)
      .catch(e => {
        logger.error(e);
        return { ticketEnabled: false };
      });
  }

  return { ticketEnabled: false };
};

// This common event data is fetched everywhere
export const fetchCommonEventData = async (eventId: string) => {
  const [{ eventInfo }, tabsConfig, ticketingProduct] = await Promise.all([
    fetchEventInfo(eventId),
    fetchEventTabsConfig(eventId),
    checkTicketingProduct(eventId, 'event'),
  ]);

  return { eventInfo: { ...eventInfo, ticketingProduct }, tabsConfig };
};

export const fetchEventTags = (eventId: string) =>
  httpService
    .get<EventTagsReponse>({ url: `/api/v4/events-backoffice/events/${eventId}/labels` })
    .then(r => r.data)
    .catch(logger.error);

export const getAgendaDates = (eventId?: string) =>
  httpService
    .get<{ dates: string[] }>({
      url: `/api/v4/user-preference/meeting-invitations/events/${eventId}/my-agenda/filter`,
    })
    .then(r => r.data)
    .catch(logger.error);

export const getAgendaFeed = (
  eventId: string,
  params: { userId?: string; time?: string; type?: string },
) =>
  httpService
    .get<AgendaFeedResponse>({
      url: `/api/v4/user-preference/meeting-invitations/events/${eventId}/my-agenda${getQuery(
        params,
      )}`,
    })
    .then(r => r.data)
    .catch(logger.error);

export const meetingAction = (
  meetingId: string,
  action: ScheduledItemStatus,
  from: 'invitee' | 'inviter',
) =>
  httpService
    .patch({
      url: `/api/v4/user-preference/meeting-invitations/${meetingId}/${from}/status`,
      data: { [`${from}_action`]: action },
    })
    .then(r => r.data)
    .catch(logger.error);

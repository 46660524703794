import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: PublicRuntimeConfig };

class FeatureFlag {
  private key: string;

  private staging: boolean;

  private production: boolean;

  constructor(key: string, staging: boolean, production: boolean) {
    this.key = key;
    this.staging = staging;
    this.production = production;
  }

  value = () => (publicRuntimeConfig.app_env === 'production' ? this.production : this.staging);

  setValue = (newValue: boolean) => {
    if (publicRuntimeConfig.app_env === 'production') {
      this.production = newValue;
    }
    this.staging = newValue;
  };

  // alias
  isEnabled = this.value;
}

export const FeatureFlags = {
  Show_Session_View: new FeatureFlag('Show_Session_View', true, true),
  NetworkingSession_View: new FeatureFlag('NetworkingSession_View', true, true),
  Use_Event_Access_API: new FeatureFlag('Use_Event_Access_API', true, true),
  Enable_Event_Blocking: new FeatureFlag('Enable_Event_Blocking', true, true), // TODO: enable before release
  Enable_Delegate_To_Stage: new FeatureFlag('Enable_Delegate_To_Stage', true, true),
  SHORT_LIVE_SESSION_REVALIDATION_TIME: new FeatureFlag(
    'SHORT_LIVE_SESSION_REVALIDATION_TIME',
    true,
    false,
  ),
  Fix_LiveSession_Cache_Issue: new FeatureFlag('Fix_LiveSession_Cache_Issue', true, false),
  EventSessionSchedulerExportToCalendar: new FeatureFlag(
    'EventSessionSchedulerExportToCalendar',
    true,
    true,
  ),
  TrackingEventsContext: new FeatureFlag('TrackingEventsContext', true, true),
  Enable_Session_Bookmarking: new FeatureFlag('Enable_Session_Bookmarking', true, true),
  Content_Library_Repository: new FeatureFlag('Content_Library_Repository', true, true),
  Session_User_Agenda: new FeatureFlag('Session_User_Agenda', true, true),
  Event_Ticketing: new FeatureFlag('Event_Ticketing', true, false),
};

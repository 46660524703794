// unleash feature flags
export const FEATURE_FLAGS = {
  LIVE_ADS_SECTIONS: 'live--ads-sections',
  EVT_MEETING_SCHEDULER: 'evt--meeting-scheduler',
  LIVE_RTMP_CHINA: 'live--rtmp-china',
  ENTERPRISE_TRANSCRIPTIONS: 'enterprise--transcriptions',
  DEPRECATE_CHAT: 'techno-core--chat-deprecation',
  // NEW_HOME: 'discovery--new-home'
};

export const A_B_TEST_FLAG_NAMES = {
  // [FEATURE_FLAGS.NEW_HOME]: 'home-version'
};

export const A_B_TEST_FLAG_VALUES = {
  // [FEATURE_FLAGS.NEW_HOME]: { true: 'home-new-brand', false: 'home-2018' }
};

export type A_B_TEST_FLAG_TYPE = {
  // 'test-team-discovery-home-version'?: 'home-new-brand' | 'home-2018';
  // 'test-team-discovery-search-version'?: '1' | '2';
};

import { ActiveLink } from 'components/ActiveLink';
import { UserClassReg } from 'icons';
import { Theme, Text, Button } from '@morressier/ts';
import { FaRegFileAlt, FaRegCalendarAlt, FaArrowLeft } from 'react-icons/fa';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { logEvent } from 'utils/eventTracking';
import * as Styles from './styles';

const { color } = Theme;

type Props = {
  // eslint-disable-next-line react/require-default-props
  branding?: OrganizationBranding;
  // eslint-disable-next-line react/require-default-props
  isContentLibraryMainPage?: boolean;
};

export const ContentLibraryNavigation = (props: Props) => {
  // Maybe makes sense rename to PublisherNavigation
  const router = useRouter();
  const { query } = router;
  const { identifier } = query as { identifier: string };
  const logoUrl = props.branding?.logo_url;
  const isContentLibraryMainPage = props?.isContentLibraryMainPage;

  const homeLink = `/library/${identifier}`;

  return (
    <>
      {isContentLibraryMainPage ? (
        <Link href={homeLink}>
          {logoUrl ? <Styles.TopImage src={logoUrl} /> : <Styles.TopImagePlaceholder />}
        </Link>
      ) : (
        <Link href={homeLink}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '1em',
            }}
          >
            <Button type="button" iconLeft={<FaArrowLeft />}>
              <Text>Back to {identifier.toUpperCase()}</Text>
            </Button>
          </div>
        </Link>
      )}
      <Styles.LinksContainer data-testid="LinksContainer">
        <ActiveLink href={homeLink}>
          <Styles.LinkItem>
            <FaRegCalendarAlt color={color.secondaryBrand} /> Home
          </Styles.LinkItem>
        </ActiveLink>
        <ActiveLink href={`${homeLink}/research`}>
          <Styles.LinkItem onClick={() => logEvent('CL_CLICKED_RESEARCH')}>
            <UserClassReg color={color.secondaryBrand} /> Research
          </Styles.LinkItem>
        </ActiveLink>
        <ActiveLink href={`${homeLink}/events`}>
          <Styles.LinkItem onClick={() => logEvent('CL_CLICKED_EVENTS')}>
            <FaRegFileAlt color={color.secondaryBrand} /> Events
          </Styles.LinkItem>
        </ActiveLink>
        <ActiveLink href={`${homeLink}/societies`}>
          <Styles.LinkItem onClick={() => logEvent('CL_CLICKED_SOCIETIES')}>
            <FaRegFileAlt color={color.secondaryBrand} /> Societies
          </Styles.LinkItem>
        </ActiveLink>
        <ActiveLink href={`${homeLink}/authors`}>
          <Styles.LinkItem onClick={() => logEvent('CL_CLICKED_AUTHORS')}>
            <FaRegFileAlt color={color.secondaryBrand} /> Authors
          </Styles.LinkItem>
        </ActiveLink>
      </Styles.LinksContainer>
    </>
  );
};

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://d490ad9687384a0cab3678c944c66e38@o152170.ingest.sentry.io/5964128',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler: samplingContext => {
    const transactionName = samplingContext.transactionContext?.name ?? '';
    if (
      transactionName.includes('/api/mux-thumbnail-api/[playbackId]') ||
      transactionName.startsWith('/o/_next/static/')
    ) {
      return 0.0;
    }

    return 0.1; // flat rate for all other transactions
  },

  beforeSend(event) {
    // only report unhandled exceptions
    if (!event.exception?.values?.[0]?.mechanism?.handled) {
      return event;
    }
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

import React, { useEffect } from 'react';
import {
  setAuthInfo,
  setIsLoadingProfile,
  setIsLoadingUserEvents,
  setProfile,
  setUserEvents,
  useAuthContext,
} from 'store';
import getConfig from 'next/config';
import { logger } from 'utils/logger';
import { isCustomDomain } from 'utils/misc';
import httpService from 'services/httpService';
import { useLocation } from 'hooks/useLocation';
import { getNominatedAbstract, getOpenSubmissionCount } from 'api/submissons';
import { setAuthInit, setSubmissionOpen } from '../../store/modules/auth/actions';

const {
  publicRuntimeConfig: { assetPrefix, client, api_base },
} = getConfig();

export const attemptTokenRefresh = async (refreshToken: string) => {
  const data = new URLSearchParams();
  data.append('grant_type', 'refresh_token');
  data.append('refresh_token', refreshToken);

  return httpService.post<MorressierAuthInfo>({
    url: '/api/auth/v1/token',
    data,
    config: {
      auth: {
        username: client.id,
        password: client.secret,
      },
    },
  });
};

// TODO: backend needs to provide a less heavy endpoint to return user events
const getAuthUserEvents = () =>
  httpService
    .get<MorressierUserProfile>({ url: 'api/v1/common/events' })
    .then(response => response.data?.events);

const getAuthUserProfile = async () =>
  httpService.get<MorressierUserProfile>({ url: 'api/v1/common/mev2' });

export const AuthInitializer: React.FC = () => {
  const origin = useLocation()?.origin;
  const { state, dispatch } = useAuthContext();

  const authInfo = state?.authInfo;

  useEffect(() => {
    const getAuthInfo = async () => {
      if (!origin) return;
      const host = isCustomDomain() ? api_base : origin;

      try {
        const { data } = await httpService.get<MorressierAuthInfo>({
          url: `${host}${assetPrefix}/api/auth`,
        });

        const now = new Date();
        const expiry_date = data.expiry_date && new Date(data.expiry_date); // does not exist on backend yet

        // TODO: expiry_date should be added on API level as expires_in (fixed seconds) is not sufficient
        if (expiry_date && now.getTime() >= expiry_date.getTime()) {
          logger.log('attempting referesh');
          attemptTokenRefresh(data.refresh_token)
            .then(r => r?.data && dispatch(setAuthInfo(r?.data)))
            .catch(e => logger.log(e));
        } else {
          dispatch(setAuthInfo(data));
          dispatch(setAuthInit());
        }
      } catch (error) {
        logger.log(error);
        dispatch(setAuthInit({ error }));
        dispatch(setIsLoadingProfile(false));
        dispatch(setIsLoadingUserEvents(false));
      }
    };

    getAuthInfo();
  }, [dispatch, origin]);

  useEffect(() => {
    if (authInfo) {
      getAuthUserProfile()
        .then(async ({ data }) => {
          dispatch(setProfile(data));

          const [
            {
              submission_open_abstracts,
              submission_open_nominated_abstracts,
              submission_open_papers,
              submission_open_posters,
            },
            nominatedPapers,
          ] = await Promise.all([
            getOpenSubmissionCount({
              authorId: authInfo.user_id || '',
              timezoneOffset: new Date().getTimezoneOffset(),
            }),
            getNominatedAbstract({ authorId: authInfo.user_id }),
          ]);

          const filteredNominatedPapers = nominatedPapers.filter(paper => {
            const deadline = new Date(
              (paper.event as MorressierEvent)?.call_for_papers_config?.submission_end || '',
            );
            const localDeadline = new Date(
              deadline.getUTCFullYear(),
              deadline.getUTCMonth(),
              deadline.getUTCDate(),
              23,
              59,
              59,
            );

            return localDeadline >= new Date();
          });

          const submissions_open =
            submission_open_abstracts +
            submission_open_nominated_abstracts +
            submission_open_papers +
            submission_open_posters +
            filteredNominatedPapers.length;

          dispatch(setSubmissionOpen(submissions_open));
        })
        .catch(e => {
          logger.log(e);
          dispatch(setIsLoadingProfile(false));
        });

      getAuthUserEvents()
        .then(data => dispatch(setUserEvents(data)))
        .catch(e => {
          logger.log(e);
          dispatch(setIsLoadingUserEvents(false));
        });
    }
  }, [authInfo, dispatch]);

  return <></>;
};

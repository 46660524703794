import { Footer as MorressierFooter } from '@morressier/ts';

import { useLocation } from 'hooks/useLocation';
import { useAuthContext } from 'store';

export const Footer = () => {
  const { state } = useAuthContext();
  const origin = useLocation()?.origin;
  const homePage = state?.profile ? `${origin}/home` : origin;

  return <MorressierFooter mode="slim-branded" homePage={homePage} />;
};

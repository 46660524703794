import { ActiveLink } from 'components/ActiveLink';
import { UserClassReg } from 'icons';
import { Theme, StyledButton, Flexbox } from '@morressier/ts';
import { FaRegFileAlt, FaRegCalendarAlt } from 'react-icons/fa';
import Link from 'next/link';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import { logEvent } from 'utils/eventTracking';
import * as Styles from './styles';

const { color } = Theme;
const { publicRuntimeConfig } = getConfig();

// ID of ACS org, it has the same ID on staging and production
export const DPCorgId = '5eba80059652df329a983aeb';

const isMorressierHost = () => {
  // TODO:move to next.config publicConfig
  /* const morressierHosts = [
    // prod
    'www.morressier.com',
    'morressier.com',
    // staging
    'next.morressier.com',
    // local
    'localhost:3005',
  ]; */
  const repoHosts = [
    // prod
    'repo.morressier.com',
    // staging
    'repo-staging.morressier.com',
    // local
    'localhost:3010',
  ];
  return typeof window !== 'undefined' && !repoHosts.find(rh => window.location.host.includes(rh));
};

type Props = {
  // eslint-disable-next-line react/require-default-props
  branding?: OrganizationBranding;
};

export const OrganizationNavigation = (props: Props) => {
  const router = useRouter();
  const { query } = router;
  const { organization_id } = query as { organization_id: string };
  const logoUrl = props.branding?.logo_url;

  const homeLink = isMorressierHost()
    ? `/organizations/${organization_id}`
    : `${typeof window !== 'undefined' && window.location.origin}`;

  return (
    <>
      <Link href={homeLink}>
        {logoUrl ? <Styles.TopImage src={logoUrl} /> : <Styles.TopImagePlaceholder />}
      </Link>
      <Styles.LinksContainer data-testid="LinksContainer">
        <ActiveLink href={homeLink}>
          <Styles.LinkItem>
            <FaRegCalendarAlt color={color.secondaryBrand} /> Home
          </Styles.LinkItem>
        </ActiveLink>
        <ActiveLink href={`/organizations/${organization_id}/events`}>
          <Styles.LinkItem
            onClick={() => {
              logEvent('CL_CLICKED_CONFERENCES');
            }}
          >
            <UserClassReg color={color.secondaryBrand} /> Conferences
          </Styles.LinkItem>
        </ActiveLink>
        <ActiveLink href={`/organizations/${organization_id}/documents`}>
          <Styles.LinkItem
            onClick={() => {
              logEvent('CL_CLICKED_PRESENTATIONS');
            }}
          >
            <FaRegFileAlt color={color.secondaryBrand} /> Presentations
          </Styles.LinkItem>
        </ActiveLink>
      </Styles.LinksContainer>
      {publicRuntimeConfig.app_env === 'staging' && DPCorgId === organization_id && (
        <Flexbox alignItems="center" style={{ height: '100%' }} className="ml2">
          <StyledButton
            color="primary"
            as="a"
            href={`/organization-submission/${organization_id}`}
            visuallyDisabled={false}
            onClick={() => {
              logEvent('DPC_CLICKED_PUBLISH_ON_SCIMEETINGS');
            }}
          >
            Submit
          </StyledButton>
        </Flexbox>
      )}
    </>
  );
};

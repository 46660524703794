import { useEffect } from 'react';

import { AppProps } from 'next/app';
import getConfig from 'next/config';
import Router, { useRouter } from 'next/router';
import Script from 'next/script';

import { Theme, ToastifyGlobalStyle, media } from '@morressier/ts';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import pick from 'lodash/pick';
import { ToastContainer, toast } from 'react-toastify';
import styled, { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { AuthClientWidget } from 'components/AuthClient';
import { AuthInitializer } from 'components/AuthInitializer';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Footer } from 'components/Footer';
import { SwrProvider } from 'components/SwrProvider';
import { ThemedPage } from 'components/ThemedPage';
import { TopNavigation } from 'components/TopNavigation';
import { FeatureFlags } from 'config/featureFlags';
import { MainWrapper } from 'containers/styles';
import { AuthProvider, EventsProvider } from 'store';
import { getServerConfig } from 'utils/config';
import {
  FeatureFlagProvider,
  useFeatureFlagContext,
} from 'utils/unleashFeatureFlags/FeatureFlagProvider';

import GlobalStyle from '../globalStyles';
import '@morressier/ts/css/utils.global.css';
import '@morressier/ts/css/tokens.css';

/**
  This import fixes some chrome related Toastify issues.
  TODO: Fix in shared-ts
*/
import 'react-toastify/dist/ReactToastify.css';
import { FEATURE_FLAGS } from 'utils/unleashFeatureFlags/flags';

const { gaId, segment_id } = getServerConfig();
const { publicRuntimeConfig } = getConfig();

// TODO: align types in organization pages
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getOrgBranding = (pageProps: any) =>
  pageProps?.organization?.brand_theme ?? pageProps?.data?.organization?.brand_theme;

const getEventBranding = event =>
  event?.brand_theme || event?.banner_url || event?.logo_url
    ? (pick(event, ['brand_theme', 'banner_url', 'logo_url']) as MorressierEventBranding)
    : undefined;

const getContentLibraryBranding = libraryPage => libraryPage?.theme;

// In order to track user activity and pass the referrer to the tracking event
// we need to update the referrer every time the location changes since this behavior
// is not working by default in single page applications
function updateReferrer() {
  Object.defineProperty(document, 'referrer', {
    value: document.location.href,
    configurable: true,
  });
}

const getCookieValue = (cookieStr: string, cookie: string) =>
  cookieStr.split(`${cookie}=`)[1].split(';')[0];

export default function App({
  Component,
  pageProps,
}: AppProps<{
  isContentLibraryMainPage?: boolean;
  data?: { event: MorressierEvent };
}>): JSX.Element {
  const orgBranding = getOrgBranding(pageProps);
  const eventBranding = getEventBranding(pageProps?.data?.event);
  const contentLibraryBranding = getContentLibraryBranding(pageProps);
  const { pathname } = useRouter();
  const isProceedingsRoute = pathname.includes('/event/[eventId]/proceeding/[proceeding_id]');

  useEffect(() => {
    const handleUrlChange = () => {
      setTimeout(updateReferrer, 300);
    };
    Router.events.on('routeChangeComplete', handleUrlChange);

    handleUrlChange();

    return () => {
      Router.events.off('routeChangeComplete', handleUrlChange);
    };
  }, []);
  const { features } = useFeatureFlagContext();
  const deprecateChatFlag = features[FEATURE_FLAGS.DEPRECATE_CHAT];
  return (
    <>
      <Script
        id="dispatcher"
        src={publicRuntimeConfig.DISPATCHER_SRC}
        strategy="beforeInteractive"
        onReady={() => {
          // @ts-ignore
          window.authEventBus = window.authEventBus || new Dispatcher();

          // @ts-ignore
          authEventBus.addListener('loginComplete', details => {
            /* For ORCID its sending cookie information in above 'details' variable
             * and for Google its sending cookie information in `details.setCookieInformation' variable
             * that is why we are having this conditional code 'details.setCookieInformation ?? details'
             */
            const sIdCookie = getCookieValue(
              details.setCookieInformation ?? details,
              'connect.sid',
            );
            document.cookie = `connect.sid=${sIdCookie}; path=/`;
            // @ts-ignore
            authEventBus?.dispatch('closeLoginModal');
            window.history.go(0);
          });
        }}
      />
      <AuthClientWidget />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.ga =
            window.ga ||
            function () {
              (ga.q = ga.q || []).push(arguments);
            };
          ga.l = +new Date();
          ga(
            'create',
            '${gaId}',
            'auto'
          );
          ga('require', 'urlChangeTracker');
          ga('require', 'eventTracker', {
            events: ['click', 'submit'],
            hitFilter: function (model, element, event) {
              model.set('eventAction', event.type, true);
            }
          });
          ga('send', 'pageview');
          window.addEventListener('hashchange', function () {
            var page = location.pathname + location.search + location.hash;
            ga('set', 'page', page);
            ga('send', 'pageview');
          });
          if (location.hostname === 'localhost') {
            ga('set', 'sendHitTask', null);
          }
        `}
      </Script>
      {FeatureFlags.TrackingEventsContext.isEnabled() ? (
        <Script id="google-analytics-segment" strategy="afterInteractive">
          {`
            !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src=("https:"===document.location.protocol?"https://":"http://")+document.location.host+"/segment/"+key;var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=('${segment_id}');analytics._cdn="https://analytics.cdn.morressier.com";analytics.SNIPPET_VERSION="4.15.3";
            analytics.load('${segment_id}');
            analytics.page();
            }}();
          `}
        </Script>
      ) : (
        <Script id="google-analytics-segment" strategy="afterInteractive">
          {`
              !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src=("https:"===document.location.protocol?"https://":"http://")+document.location.host+"/segment/"+t;var o=document.getElementsByTagName("script")[0];o.parentNode.insertBefore(n,o);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
              analytics.load('${segment_id}');
              analytics.page();
              }}();
            `}
        </Script>
      )}
      <Script src="//fast.appcues.com/83489.js" />
      <Script src="https://rum-static.pingdom.net/pa-5cb45d655c1f440017000016.js" />
      <GlobalStyle />
      <StyledComponentsThemeProvider theme={Theme}>
        <ErrorBoundary>
          <SwrProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <AuthProvider>
                <FeatureFlagProvider>
                  <AuthInitializer />
                  <EventsProvider>
                    <ThemedPage event={pageProps?.data?.event}>
                      <TopNavigation
                        eventData={pageProps?.data?.event}
                        eventBranding={eventBranding}
                        organizationBranding={orgBranding}
                        contentLibraryBranding={contentLibraryBranding}
                        isContentLibraryMainPage={pageProps?.isContentLibraryMainPage}
                      />
                      <MainWrapper>
                        <Component {...pageProps} />
                        <ToastifyGlobalStyle />
                        <ToastContainer
                          position={toast.POSITION.TOP_RIGHT}
                          autoClose={3000}
                          hideProgressBar
                          newestOnTop
                          closeOnClick
                          draggable={false}
                          pauseOnHover
                        />
                      </MainWrapper>
                      <Footer />
                    </ThemedPage>
                  </EventsProvider>
                </FeatureFlagProvider>
              </AuthProvider>
            </MuiPickersUtilsProvider>
          </SwrProvider>
        </ErrorBoundary>

        <Script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/25151219.js"
        />
      </StyledComponentsThemeProvider>
    </>
  );
}

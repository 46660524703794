import getConfig from 'next/config';

import { ThemeProvider } from 'styled-components';

import { useEventContext } from 'store';

import { MuiTheme } from './muiTheme';
import * as Styles from './styles';

const { publicRuntimeConfig } = getConfig();

type TextProps = {
  fontSize: string;
  lineHeight: string;
  font: string;
};

interface Props {
  event: MorressierEvent | undefined;
}

export type Theme = {
  breakpoints: {
    xxsmall: number;
    xsmall: number;
    small: number;
    medium: number;
    large: number;
    xlarge: number;
  };
  sidebarWidth: string;
  topbarHeight: string;
  color: {
    [x: string]: string;
  };
  borderRadius: {
    [x: string]: string;
  };
  textSizes: {
    [x: string]: TextProps;
  };
  palettes: {
    [x: string]: {
      [x: string]: string;
    };
  };
};

const customTheme = (branding?: MorressierEvent['brand_theme']) => (theme: Theme) => {
  if (branding?.primary_color) {
    return {
      ...theme,
      color: {
        ...theme.color,
        primaryBrand: branding.primary_color,
      },
    };
  }

  return theme;
};

export const ThemedPage: React.FC<Props> = ({ children, event }) => {
  const { state } = useEventContext();

  return (
    <>
      <ThemeProvider theme={customTheme(state?.brand_theme)}>
        <MuiTheme>
          <Styles.AppPagesContainer fullHeight={!!state?.brand_theme}>
            {children}
          </Styles.AppPagesContainer>
        </MuiTheme>
      </ThemeProvider>
    </>
  );
};

import getConfig from 'next/config';

type SharedConfig = {
  app_env: 'local' | 'staging' | 'production';
  node_env: 'development' | 'production';
  api_base: string;
  home_path: string;
};

type ServerConfig = {
  gaId: string;
  ga4Id: string;
  segment_id: string;
} & SharedConfig;
type ClientConfig = {
  assetPrefix: string;
} & SharedConfig;

export const getClientConfig = (): ClientConfig => getConfig().publicRuntimeConfig;

export const getServerConfig = (): ServerConfig => getConfig().serverRuntimeConfig;

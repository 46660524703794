export const DISCUSSION_PRESENTATION_UPDATED = (documentId: string, sessionId?: string) =>
  // currently it uses posterID+sessionId, this should be changed to presentationId
  ({
    eventType: 'discussion-presentation-updated',
    channelName: sessionId
      ? `discussion-presentation-${documentId}-${sessionId}`
      : `discussion-presentation-${documentId}`,
  });

export const DISCUSSION_PRESENTATION_DELETED = (documentId: string, sessionId?: string) =>
  // currently it uses posterID, this should be changed to presentationId
  ({
    eventType: 'discussion-presentation-deleted',
    channelName: sessionId
      ? `discussion-presentation-${documentId}-${sessionId}`
      : `discussion-presentation-${documentId}`,
  });

export const SESSION_DISCUSSION_PRESENTATION_UPDATED = (sessionId: string) => ({
  eventType: 'session_discussion-presentation-updated',
  channelName: `discussion-presentation-${sessionId}`,
});

export const SESSION_DISCUSSION_PRESENTATION_DELETED = (sessionId: string) => ({
  eventType: 'session_discussion-presentation-deleted',
  channelName: `discussion-presentation-${sessionId}`,
});

import { useReducer } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { Button, Text } from '@morressier/ts';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faHouse,
  faScreenUsers,
  faUsers,
  faShop,
  faBars,
  faFileLines,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FaRegCalendarAlt, FaArrowLeft, FaTimes } from 'react-icons/fa';

import { ActiveLink } from 'components/ActiveLink';
import { useEventAccess } from 'components/EventStandardContainer/hooks';
import { useEventBranding } from 'hooks/useEventBranding';
import { useEventTabsConfig } from 'hooks/useEventTabsConfig';



import { useAuthContext } from 'store';
import { logEvent } from 'utils/eventTracking';
import { FeatureFlags } from 'config/featureFlags';
import { useFeatureFlagContext } from 'utils/unleashFeatureFlags/FeatureFlagProvider';
import { FEATURE_FLAGS } from 'utils/unleashFeatureFlags/flags';

import * as Styles from './styles';

export const EventNavigation: React.FC<{
  eventBranding?: MorressierEventBranding;
  event?: MorressierEvent;
}> = props => {
  const { pathname, query } = useRouter();
  const { contentLibrary, contentLibraryTitle, from } = query as {
    contentLibrary?: string;
    contentLibraryTitle?: string;
    fromSearch?: string;
    from?: string;
  };
  const eventId = props.event?.id as string;

  /**
   * this is a temporal hook
   * we expect all pages to fetch event branding info in getStaticProps
   */
  const { data } = useEventBranding(props.eventBranding ? null : eventId);
  const { data: tabsConfig } = useEventTabsConfig(eventId);

  const branding = data || props.eventBranding;
  const logo = branding?.logo_url || branding?.brand_theme?.header_logo_url;

  const { state } = useAuthContext();
  const { features } = useFeatureFlagContext();

  const event = props?.event;
  const isAuthenticated = !!state?.profile?.email;
  const isEmbargoed = !!event?.embargoed;
  const isArchived = !!event?.archived;
  const isPublished = !!event?.publish_date && new Date() > new Date(event?.publish_date);
  const isDesktop = useMediaQuery('(min-width: 1280px)');
  const [isFlyoutOpen, toggleFlyoutOpen] = useReducer(s => !s, false);

  const tabs = [
    {
      condition: tabsConfig?.home?.isEnabled,
      pathname: '/event/[eventId]',
      icon: <FontAwesomeIcon icon={faHouse as IconProp} />,
      label: tabsConfig?.home?.label || 'Home',
      mobileOrder: 1,
    },
    {
      condition: tabsConfig?.sessions?.isEnabled,
      pathname: '/event/[eventId]/sessions',
      isActive: pathname.includes('/sessions') || pathname.includes('/networking-sessions'),
      icon: <FontAwesomeIcon icon={faScreenUsers as IconProp} />,
      label: tabsConfig?.sessions?.label || 'Sessions',
      mobileOrder: 2,
    },
    {
      condition: tabsConfig?.submissions?.isEnabled,
      pathname: '/event/[eventId]/submissions',
      isActive: pathname.includes('/submissions'),
      icon: <FontAwesomeIcon icon={faFileLines as IconProp} />,
      label: tabsConfig?.submissions?.label || 'Submissions',
      mobileOrder: 5,
    },
    {
      condition: tabsConfig?.people?.isEnabled,
      pathname: '/event/[eventId]/people',
      isActive: pathname.includes('/people'),
      icon: <FontAwesomeIcon icon={faUsers as IconProp} />,
      label: tabsConfig?.people?.label || 'People',
      mobileOrder: 3,
    },
    {
      condition: tabsConfig?.exhibitors?.isEnabled,
      pathname: '/event/[eventId]/exhibitors/page/1',
      isActive: pathname.includes('/exhibitors'),
      icon: <FontAwesomeIcon icon={faShop as IconProp} />,
      label: tabsConfig?.exhibitors?.label || 'Exhibitors',
      mobileOrder: 4,
    },
    {
      condition: !!features[FEATURE_FLAGS.EVT_MEETING_SCHEDULER],
      pathname: '/event/[eventId]/my-agenda',
      isActive: pathname.includes('/my-agenda'),
      icon: <FaRegCalendarAlt />,
      label: 'My Agenda',
      showDesktopIcon: true,
      mobileOrder: 6,
    },
  ];

  const activeTabs = tabs.filter(t => t.condition);

  // We have a different tab order on mobile
  if (!isDesktop) {
    activeTabs.sort((a, b) => a.mobileOrder - b.mobileOrder);
  }

  // Calculate whether to show "More" button and flyout on mobile
  const needsFlyout = activeTabs.length > 5;
  const tabsInFooter = activeTabs.slice(0, needsFlyout ? 4 : 5);
  const tabsInFlyout = activeTabs.slice(needsFlyout ? 4 : 3);

  const { eventAccess, isLoadingAccess } = useEventAccess(event?.id as string, {
    isEmbargoed,
    isPublished,
  });

  const getIsBlocked = () => {
    const enableNewBlurBehaviour = FeatureFlags.Event_Ticketing.isEnabled();

    if (enableNewBlurBehaviour) return false;
    if (isLoadingAccess && isEmbargoed) return true;
    if (isEmbargoed && (!isAuthenticated || !eventAccess?.hasAccess)) return true;
    if (!isPublished && !eventAccess?.isOrganizer) return true;

    return false;
  };

  const isBlocked = getIsBlocked();
  const TopImage = logo ? (
    <Styles.TopImage src={logo} />
  ) : (
    <Styles.TopImage as="div" empty>
      <FaRegCalendarAlt />
    </Styles.TopImage>
  );

  const linkParams = {
    eventId,
    ...(contentLibrary ? { contentLibrary, contentLibraryTitle } : {}),
    ...(from ? { from } : {}),
  };
  const handleBackButton = () => {
    if (contentLibraryTitle) {
      logEvent('BACK_TO_LIBRARY_BUTTON', {
        organization_id: event?.organization_id?._id,
        event_id: eventId,
      });
      const clPagePath = from || `/library/${contentLibrary}`;
      document.location.href = `${document.location.href.split('/o/')[0]}/cl${clPagePath}`;
    }
  };

  const renderLogoOrBackLink = () => {
    if (contentLibrary) {
      const label = `Back to ${contentLibraryTitle}`;
      return (
        <Styles.BackButtonContainer alignItems="center">
          <Button onClick={handleBackButton} type="button" iconLeft={<FaArrowLeft />} width="100%">
            <Text
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              size={isDesktop ? 'body1_new' : 'body2_new'}
            >
              {label}
            </Text>
          </Button>
        </Styles.BackButtonContainer>
      );
    }
    return <Link href={`/event/${eventId}`}>{TopImage}</Link>;
  };

  return branding ? (
    <>
      {renderLogoOrBackLink()}

      {/* Mobile flyout */}
      {isFlyoutOpen && !isDesktop ? (
        <Styles.MobileFlyout>
          <Styles.CloseIcon onClick={toggleFlyoutOpen}>
            <FaTimes />
          </Styles.CloseIcon>
          {tabsInFlyout.map(tab => (
            <Link href={{ pathname: tab.pathname, query: linkParams }}>
              <Styles.FlyoutItem>
                {tab.icon}
                {tab.label}
              </Styles.FlyoutItem>
            </Link>
          ))}
        </Styles.MobileFlyout>
      ) : null}

      <Styles.LinksContainer data-testid="LinksContainer">
        {isLoadingAccess || isBlocked || isArchived ? null : (
          <>
            {(isDesktop ? activeTabs : tabsInFooter).map(tab => (
              <ActiveLink
                href={{ pathname: tab.pathname, query: linkParams }}
                isActive={tab.isActive}
              >
                <Styles.LinkItem showDesktopIcon={tab.showDesktopIcon}>
                  {tab.icon} {tab.label}
                </Styles.LinkItem>
              </ActiveLink>
            ))}

            {needsFlyout && !isDesktop ? (
              <Styles.LinkItem onClick={toggleFlyoutOpen}>
                <FontAwesomeIcon icon={faBars as IconProp} />
                More
              </Styles.LinkItem>
            ) : null}
          </>
        )}
      </Styles.LinksContainer>
    </>
  ) : null;
};

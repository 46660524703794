import { ReactNode, useEffect } from 'react';

import { useRouter } from 'next/router';

import { HTMLText, MessengerMenuItem, Theme, UserSection } from '@morressier/ts';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { toast } from 'react-toastify';

import { SpinnerIcon } from 'components/Spinner';
import { FeatureFlags } from 'config/featureFlags';
import { useLocation } from 'hooks/useLocation';
import { useLogIn } from 'hooks/useLogIn';
import { useAuthContext } from 'store';
import { PusherHandler } from 'store/modules/pusher';
import { logEvent } from 'utils/eventTracking';
import { NEW_NOTIFICATION } from 'utils/pusher/notifications';
import { useFeatureFlagContext } from 'utils/unleashFeatureFlags/FeatureFlagProvider';
import { FEATURE_FLAGS } from 'utils/unleashFeatureFlags/flags';

import { ContentLibraryNavigation } from './ContentLibraryNavigation';
import { EventNavigation } from './EventNavigation';
import { useNotifications } from './hooks';
import { OrganizationNavigation } from './OrganizationNavigation';
import * as Styles from './styles';

export const renderLink = (params: {
  discoveryProps?: any; // eslint-disable-line
  contentProps?: {
    to: string;
  };
  newTab?: boolean;
  href: string;
  children: ReactNode;
}) => (
  <a
    key={params.href}
    href={params.href}
    target="_blank"
    rel="noreferrer"
    style={{ textDecoration: 'none' }}
  >
    {params.children}
  </a>
);

export const TopNavigation: React.FC<{
  organizationBranding?: OrganizationBranding;
  eventBranding?: MorressierEventBranding;
  eventData?: MorressierEvent;
  contentLibraryBranding?: OrganizationBranding;
  isContentLibraryMainPage?: boolean;
}> = props => {
  const { pathname, push } = useRouter();
  const { features } = useFeatureFlagContext();

  const origin = useLocation()?.origin;
  const { state: auth } = useAuthContext();
  const isMediumUp = useMediaQuery(`(min-width:${Theme.breakpoints.medium}px)`);

  const isAuthenticated = Boolean(auth?.authInfo && auth.profile);
  const profile = auth?.profile;
  const newNotification = isAuthenticated && NEW_NOTIFICATION(profile?.id as string);

  const {
    notifications,
    addNewNotification,
    markAllNotificationsSeen,
    markAllNotificationsRead,
    markSingleNotificationRead,
    loadMore,
  } = useNotifications(isAuthenticated);

  const { handleLogIn, handleSignUp } = useLogIn();
  const deprecateChatFlag = features[FEATURE_FLAGS.DEPRECATE_CHAT];

  const LogoAndNavigationTabs = () => {
    if (pathname.startsWith('/event/[eventId]')) {
      return <EventNavigation event={props.eventData} eventBranding={props.eventBranding} />;
    }
    if (pathname.startsWith('/organizations/')) {
      return <OrganizationNavigation branding={props.organizationBranding} />;
    }
    // contentLibrary
    if (pathname.startsWith('/library/')) {
      return (
        <ContentLibraryNavigation
          branding={props.contentLibraryBranding}
          isContentLibraryMainPage={props.isContentLibraryMainPage}
        />
      );
    }
    return null;
  };

  return (
    <Styles.Navbar>
      <LogoAndNavigationTabs />
      <Styles.ProfileSection>
        {auth?.isLoadingProfile ? (
          <SpinnerIcon />
        ) : (
          <UserSection
            logEvent={logEvent}
            isAuthenticated={isAuthenticated}
            authStatePending={false}
            authUserId={profile?.id ?? ''}
            fullName={profile?.full_name ?? ''}
            pictureUrl={profile?.picture_url}
            onSignup={handleSignUp}
            onLogin={() => {
              const {
                _id: eventId,
                name: eventName,
                organization_id,
                short_name: shortName,
              } = props?.eventData || {};

              handleLogIn({
                eventId,
                eventName,
                shortName,
                orgAcronym: organization_id?.acronym,
                orgName: organization_id?.name,
              });
            }}
            onNotificationsClick={(toggleFlyout: () => void) => {
              if (isMediumUp) {
                toggleFlyout();
              } else {
                window.location.href = '/notifications';
              }
              markAllNotificationsSeen();
            }}
            onPendingSubmissionsClick={() => {
              logEvent('OPENED_MY_CONTRIBUTIONS');
              window.open(`${origin}/submissions`, '_blank');
            }}
            notifications={notifications}
            markAllAsRead={markAllNotificationsRead}
            loadMoreNotifications={loadMore}
            onSingleNotificationClick={(notification: MorressierNotification) => {
              if (notification) {
                logEvent('NOTIFICATION_CLICKED', {
                  type: notification.type,
                });
                if (!notification.read) {
                  markSingleNotificationRead(notification.id);
                }
                if (notification.action !== '') {
                  if (notification.action.substring(0, 3) === '/o/') {
                    notification.action = notification.action.slice(2);
                  }
                  push(notification.action);
                }
              }
            }}
            pendingSubmissions={Number(profile?.submissions_open ?? 0)}
            hideSavedResearch
            hideContentStatistics
            copies={{
              notifications: {
                markAllAsRead: 'Mark all as read',
                emptyMessage: 'There are no updates at this time.',
                getNotificationBodyText: notification =>
                  (
                    <HTMLText additionalTags={['b']}>{notification.body}</HTMLText>
                  ) as unknown as string,
              },
              pendingSubmissions: {
                getButtonCopy: submissionsOpen =>
                  submissionsOpen === 1
                    ? 'Submit 1 document'
                    : `Submit ${submissionsOpen} documents`,
                getFlyoutCopy: submissionsOpen =>
                  submissionsOpen === 1
                    ? 'You have 1 poster that needs to be submitted.'
                    : `You have ${submissionsOpen} posters that need to be submitted.`,
              },
            }}
           />
        )}
        {newNotification && (
          <PusherHandler
            channel={newNotification.channelName}
            event={newNotification.eventType}
            onUpdate={addNewNotification}
          />
        )}
      </Styles.ProfileSection>
    </Styles.Navbar>
  );
};

import httpService from 'services/httpService';
import { SWRConfig } from 'swr';

const fetcher = (url: string) => httpService.get({ url }).then(res => res.data);

export const SwrProvider: React.FC = ({ children }) => (
  <SWRConfig value={{ fetcher, errorRetryCount: 3, revalidateOnFocus: false }}>
    {children}
  </SWRConfig>
);

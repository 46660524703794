import styled, { css } from 'styled-components';

export const AppPagesContainer = styled.div<{ fullHeight?: boolean }>`
  min-height: 100vh;
  width: 100%;
  position: relative;
  padding-top: 64px;

  @media screen and (max-width: 1024px) {
    ${props =>
      props.fullHeight &&
      css`
        min-height: calc(100vh - 66px);
      `}
  }
`;

import { createContext, useContext, Dispatch, useReducer } from 'react';
import { AuthActions } from './actions';

export type AuthState = {
  authInfo?: MorressierAuthInfo;
  user?: MorressierUser;
  profile?: MorressierUserProfile;
  userEvents?: UserEvent[];
  isInit: boolean;
  isLoadingProfile: boolean;
  isLoadingUserEvents: boolean;
  error: any;
};
const initialState = {
  isInit: false,
  isLoadingProfile: true,
  isLoadingUserEvents: true,
} as AuthState;

const AuthContext = createContext<{
  state?: AuthState;
  dispatch: Dispatch<AuthActions>;
}>({ state: undefined, dispatch: () => null });

const reducer = (state: AuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case 'setAuthInit': {
      return { ...state, isInit: true, error: action.payload };
    }
    case 'setAuthInfo': {
      return { ...state, authInfo: action.payload };
    }

    case 'setAuthUser': {
      return { ...state, user: action.payload };
    }

    case 'setProfile': {
      return { ...state, profile: action.payload, isLoadingProfile: false };
    }

    case 'setSubmissionOpen': {
      return {
        ...state,
        profile: state.profile && { ...state.profile, submissions_open: action.payload },
      };
    }

    case 'setIsLoadingProfile': {
      return { ...state, isLoadingProfile: action.payload };
    }

    case 'setUserEvents': {
      return { ...state, userEvents: action.payload, isLoadingUserEvents: false };
    }

    case 'setIsLoadingUserEvents': {
      return { ...state, isLoadingUserEvents: action.payload };
    }

    case 'logOut': {
      return { ...initialState, isLoadingProfile: false, isLoadingUserEvents: false };
    }

    default:
      return initialState;
  }
};

export const AuthProvider: React.FC = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AuthContext.Provider value={{ state, dispatch }}>{props.children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context.state || !context.dispatch) {
    throw new Error('AuthContext must be used within AuthProvider');
  }

  return context;
};

import styled from 'styled-components';
import { CgSpinner } from 'react-icons/cg';
import { color } from '@morressier/ts';

export const SpinnerIcon = styled(CgSpinner)`
  animation-name: spin;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  path {
    fill: ${color('secondaryBrand')};
  }
`;

export default SpinnerIcon;

import Pusher from 'pusher-js';
import { setPusherClient } from 'react-pusher';
import getConfig from 'next/config';

export * from './notifications';
export * from './discussions';
export * from './sessions';
export * from './rtmpStatus';

export const initializePusher = () => {
  const { publicRuntimeConfig } = getConfig();
  const PUSHER_KEY = publicRuntimeConfig.PUSHER_KEY || '';
  const pusherClient = new Pusher(PUSHER_KEY as string, {
    cluster: 'eu',
    forceTLS: true,
    authEndpoint: '/pusher/auth',
  });

  Pusher.logToConsole = publicRuntimeConfig.NODE_ENV === 'development';
  setPusherClient(pusherClient);
};

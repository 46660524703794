import values from 'lodash/values';

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

/**
 * This function uses the comma(,) seperator to split and join an array of strings
 * @param items - array of strings
 * @param maxItemsBeforeAnd - max number of items to show from input array of strings before "and"
 * @returns
 */
export const arrayCommaJoiner = (items: string[], maxItemsBeforeAnd = items.length) => {
  if (items.length <= 2) {
    return items.join(' and ');
  }

  if (maxItemsBeforeAnd >= items.length) {
    const lastIndex = items.length - 1;
    const itemsToShow = items.slice(0, lastIndex);

    return `${itemsToShow.join(', ')}, and ${items[lastIndex]}`;
  }

  const itemsToShow = items.slice(0, maxItemsBeforeAnd);
  const remaining = items.length - maxItemsBeforeAnd;

  return `${itemsToShow.join(', ')}, and ${remaining} ${remaining === 1 ? 'other' : 'others'}`;
};

// export const asyncForEach = async (
//   array: string[],
//   callback: (element: string, index: number, array: string[]) => Promise<void>,
// ) => {
//   for (let index = 0; index < array.length; index + 1) {
//     await callback(array[index], index, array);
//   }
// };

export const merge = <T extends Record<string, any>>( // eslint-disable-line
  oldArray: T[],
  newArray: T[],
) => {
  // If an element found in payload doesn't exist yet in state,
  // it just gets added. Otherwise, we merge it with the existing one.
  const tempObj = oldArray.concat(newArray).reduce(
    (accumulated, element) => ({
      ...accumulated,
      [element.id]: accumulated[element.id] ? { ...accumulated[element.id], ...element } : element,
    }),
    {} as Record<string, T>,
  ); // turns result tempObj back into an array before returning it as new state

  return values(tempObj);
};

// Like Boolean(), but correctly infers resulting type
type FalsyType = false | null | undefined | '' | 0;
export function typedBoolean<ValueType>(value: ValueType): value is Exclude<ValueType, FalsyType> {
  return Boolean(value);
}

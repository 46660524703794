import JsSHA from 'jssha';
import getConfig from 'next/config';
import httpService from 'services/httpService';
import { logger } from './logger';

const log = logger;
const { publicRuntimeConfig } = getConfig();

// TODO should be moved to appropriate .d.ts file
declare global {
  interface Window {
    analytics: {
      user: () => any;
      track: Function;
    };
    Appcues: {
      identify: Function;
      track: Function;
      page: () => void;
    };
    ReactIntlLocaleData: Record<string, any>;
  }
}

type AuthUser = any; // TODO:
const isPostConference = (user: AuthUser) => {
  const now = new Date();
  const firstEventEndDate = user.first_event_end_date && new Date(user.first_event_end_date);
  return !!firstEventEndDate && now.getTime() > firstEventEndDate.getTime();
};

const getUserHash = (userId: string): string => {
  const shaObj = new JsSHA('SHA-256', 'TEXT');
  shaObj.setHMACKey('leoj9As0baaJtMtA3nDXEsTg1ttrOQd19mdetcs7', 'TEXT');

  if (userId) {
    shaObj.update(userId);
  }
  return shaObj.getHMAC('HEX');
};

// eslint-disable-next-line consistent-return
export const logEvent = (event: string, properties?: {}): void | Promise<any> => {
  log.log('logEvent:', event, properties);
  let anonymousId = '';

  if (publicRuntimeConfig.NODE_ENV === 'development') {
    log.debug('[segment][event]', event, properties);
  } else if (typeof window !== 'undefined' && window?.analytics?.user) {
    try {
      window.Appcues.track(event, properties);
    } catch {
      //
    }
    anonymousId = window.analytics.user().anonymousId();
  } else {
    log.error(`[segment][event] No segment instance available, cannot track ${event}`);
  }

  return httpService
    .post({
      url: '/morressier-user-activity/log',
      data: {
        anonymousId,
        event,
        properties,
        context: {
          page: {
            url: window.location.href,
            referrer: window.document.referrer,
            path: window.location.pathname,
            search: window.location.search,
            title: window.document.title,
          },
        },
        fallback: false,
      },
    })
    .catch(e => logger.log(e));
};

export const identify = (authUser: MorressierUserProfile, traits?: {}, options?: {}) => {
  if (publicRuntimeConfig.NODE_ENV === 'development') {
    log.debug('[segment][identify user]', 'traits', traits, 'options', options);
  }

  if (typeof window !== 'undefined' && window?.analytics?.user) {
    try {
      window.Appcues.identify(getUserHash(authUser.id), {
        email: authUser.email,
      });
    } catch {
      //
    }

    httpService.post({
      url: '/morressier-user-activity/identify',
      data: {
        anonymousId: window.analytics.user().anonymousId(),
        traits: {
          post_conference: isPostConference(authUser),
          email: authUser.email,
          name: authUser.full_name,
          user_account_type:
            authUser?.access_permissions && authUser.access_permissions.analytics_dashboard
              ? 'premium'
              : 'free',
          ...traits,
        },
        integrations: {
          Intercom: {
            user_hash: getUserHash(authUser.id),
          },
        },
        ...options,
      },
    });
  } else {
    log.error('[segment][identify user] No segment instance available, cannot set user id');
  }
};

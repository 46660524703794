import { ReactNode, useContext, createContext } from 'react';
import { useAuthContext } from 'store';
import { UnleashClient } from 'unleash-proxy-client';
import { useUnleashed, useFeatureFlag } from './featureFlag';
import { FEATURE_FLAGS } from './flags';

type FeatureFlagProviderProps = {
  children: ReactNode;
};

type TFeatureFlags = {
  features: {
    [key: string]: boolean;
  };
  useFeatureFlag: (flagName: string) => boolean;
  unleash: UnleashClient | null;
};

// all possible feature flag names
const featuresFlags = Object.values(FEATURE_FLAGS);
// by default we set all feature flags to false
const defaultFeatureFlagValues = featuresFlags.reduce(
  (acc, value) => ({ ...acc, [value]: false }),
  {},
);
const defaultState = {
  features: defaultFeatureFlagValues,
  useFeatureFlag,
  unleash: null,
};
const FeatureFlagContext = createContext<TFeatureFlags>(defaultState);

export function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
  const userData = useAuthContext();
  const { features, unleash } = useUnleashed(featuresFlags, {
    userId: userData?.state?.profile?._id,
  });
  const state = {
    features,
    useFeatureFlag,
    unleash,
  };

  return <FeatureFlagContext.Provider value={state}>{children}</FeatureFlagContext.Provider>;
}

export const useFeatureFlagContext = () => useContext(FeatureFlagContext);

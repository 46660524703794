import { createContext, useContext, Dispatch, useReducer } from 'react';
import { EventActions } from './actions';

const initialState = {} as Partial<MorressierEvent> & {
  tabsConfig?: MorressierEventTabsConfiguration;
};
type EventState = typeof initialState;

const EventsContext = createContext<{
  state?: EventState;
  dispatch: Dispatch<EventActions>;
}>({ state: undefined, dispatch: () => null });

const reducer = (state: EventState, action: EventActions): EventState => {
  switch (action.type) {
    case 'setEvent': {
      return { ...action.payload };
    }

    case 'setEventBranding': {
      return { ...state, ...action.payload };
    }

    case 'setEventTabsConfig': {
      return { ...state, tabsConfig: action.payload };
    }

    default:
      return initialState;
  }
};

export const EventsProvider: React.FC = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EventsContext.Provider value={{ state, dispatch }}>{props.children}</EventsContext.Provider>
  );
};

export const useEventContext = () => {
  const context = useContext(EventsContext);
  if (!context.state || !context.dispatch) {
    throw new Error('useEventContext must be used within EventsProvider');
  }

  return context;
};

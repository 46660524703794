import { setTimeout } from 'timers';

import { useRouter } from 'next/router';

import useSWR from 'swr';

import { fetchEventInfo } from 'api/events';
import { useAuthedUser } from 'hooks/useIsAuthedUser';
import { ProductAccessResponse } from 'pages/api/product/has-access';
import httpService from 'services/httpService';
import { useAuthContext } from 'store';

export type UserEventAccess = {
  isOrganizer?: boolean;
  isAuthor?: boolean;
  isDelegate?: boolean;
  isChair?: boolean; // TODO: needs to be added in BE response
  isPresenter?: boolean;
  hasAccess: boolean;
  // isChair: boolean; API doesn't return this at the moment
};

const eventAccessApiUrl = (eventId: string) => `/api/v3/standalone/events/${eventId}/access`;
/**
 * Now we always have to check event access
 */
export const useEventAccess = (
  eventId: string,
  options?: {
    isEmbargoed: boolean;
    isPublished: boolean;
  },
): {
  isLoadingAccess: boolean;
  eventAccess?: UserEventAccess;
  loadingState: boolean;
  refetchAccess: (wait?: number) => Promise<UserEventAccess | undefined> | NodeJS.Timeout | null;
} => {
  const { state: authState } = useAuthContext();

  const url = eventId && authState?.isLoadingProfile === false ? eventAccessApiUrl(eventId) : null;

  const { data, error, mutate } = useSWR<UserEventAccess>(url, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
    shouldRetryOnError: false,
  });

  // we want to show loading state temporarily for embargoed event or unpublished events
  const canShowLoadingState = options ? options.isEmbargoed || !options.isPublished : true;

  return {
    eventAccess: data,
    isLoadingAccess: canShowLoadingState ? !data && !error : false,
    // the exact loading state
    loadingState: !data && !error,
    refetchAccess: (wait = 0) => {
      if (!wait) {
        return mutate();
      }

      return setTimeout(() => mutate(), wait);
    },
  };
};

type EventInfoResponse = ResolvedPromise<ReturnType<typeof fetchEventInfo>>;
export const useEventInfo = (eventId?: string, fallbackData?: EventInfoResponse) => {
  const { data, error } = useSWR<EventInfoResponse>(eventId || null, fetchEventInfo, {
    fallbackData,
    revalidateOnFocus: false,
  });

  return {
    ...data?.eventInfo,
    loading: !data && !error,
  };
};

// We would use this hook to fetch embargoed page data only on the client now.
export const useFetchPageData = (
  shouldFetchEmbargoedPageData?: boolean, // should be true when user has access to event
  fetchEmbargoedPageData?: () => { [pageData: string]: any },
) => {
  const { asPath } = useRouter();
  const pageKey = asPath.split('?')?.[0];

  const { data, error } = useSWR(shouldFetchEmbargoedPageData ? `${pageKey}` : null, () =>
    fetchEmbargoedPageData?.(),
  );

  return {
    isLoadingEmbargoedData: shouldFetchEmbargoedPageData && !data && !error,
    embargoedPageData: data,
  };
};

export enum ApsStatus {
  NoAuth = 'no-auth',
  NotAPS = 'not-aps',
  NoReg = 'not-registered',
  isRegistered = 'registered',
}

const APS_EVENTS_MAP: Record<string, string> = {
  // SHOCK
  '62a8bdf82898f2001205f87a': 'a0l5G00000JBhOWQA1', // staging url
  '6294fcdeb3a32e001425f5a9': 'a0l5G00000JBhOWQA1', // prod url
  // DAMOP-2022
  '626b031c6b98c000133e6464': 'a0l5G00000IpbdAQAR', // staging url
  '6275368a16b2ff0012b758e4': 'a0l5G00000IpbdAQAR', // prod url
  // DAMOP-2023
  '644ba3b850b8c50019fea403': 'a0l5G00000MI6u3QAD', // staging and prod url
};

export const getAPSEventId = (id: string) => APS_EVENTS_MAP[id];

export const useAPSEventAccess = (eventId: string | null) => {
  const apsEventId = eventId && getAPSEventId(eventId);
  const isAPSEvent = !!apsEventId;
  const isAuthed = useAuthedUser();

  const url =
    isAuthed && !!isAPSEvent ? `/api/v3/aps/user-registration?apsEventId=${apsEventId}` : null;

  const { data, error, isValidating } = useSWR<{ status: ApsStatus }>(url, {
    fallbackData: { status: ApsStatus.NoAuth },
  });

  return {
    APSUserStatus: data?.status as ApsStatus,
    isAPSEvent,
    apsEventId,
    isLoadingApsAccess: (!data && !error && !!url) || isValidating,
  };
};

const checkProductAccess = async (eventId: string) =>
  httpService.get<ProductAccessResponse>({
    url: `api/product/has-access?eventId=${eventId}&`,
    config: { baseURL: '/o' },
  });

export const useEventProductAccess = (eventId?: string, eventProduct?: TicketingProduct) => {
  const isAuthed = useAuthedUser();
  const { state } = useAuthContext();

  const shouldCheckProductAccess = isAuthed && !!eventId && !!eventProduct?.ticketEnabled;

  const { data, error, mutate } = useSWR(
    shouldCheckProductAccess ? [eventId] : null,
    checkProductAccess,
  );

  return {
    productAccess: data?.data || { hasAccess: false },
    isLoadingProductAccess: shouldCheckProductAccess
      ? !data && !error
      : !!eventProduct?.ticketEnabled && state?.isLoadingProfile,
    mutate,
  };
};

import { UnleashClient } from 'unleash-proxy-client';
import getConfig from 'next/config';
import { logger } from '../logger';

const {
  publicRuntimeConfig: { app_env, UNLEASH_PROXY_URL, UNLEASH_PROXY_KEY },
} = getConfig();

const configUnleash = {
  url: UNLEASH_PROXY_URL || '',
  clientKey: UNLEASH_PROXY_KEY || '',
  refreshInterval: 15,
  appName: 'standalone-app',
  environment: app_env,
};

let unleashProxyClientInstance: UnleashClient | null = null;

export { UnleashClient };
export default () => {
  if (!unleashProxyClientInstance && typeof window !== 'undefined') {
    unleashProxyClientInstance = new UnleashClient(configUnleash);
    logger.log('New unleash instance created');
  }

  return unleashProxyClientInstance;
};

import { Theme } from '@morressier/ts';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

export const breakpoints = {
  xs: 540,
  sm: 720,
  md: 1024,
  lg: 1280,
  xl: 1920,
} as const;

const theme = createTheme({
  palette: {
    primary: {
      main: Theme.color.primaryBrand,
      light: Theme.color.primaryBrandLight,
      dark: Theme.color.primaryBrandHover,
      contrastText: Theme.color.white,
    },
    secondary: {
      main: Theme.color.white,
      light: Theme.color.disabledInputBackground,
      dark: Theme.color.lightGrey,
      contrastText: Theme.color.secondaryBrand,
    },
  },
  typography: {
    // useNextVariants: true,
    fontFamily: '"OpenSans-SemiBold", sans-serif',
    fontSize: 16,
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl,
    },
  },
});

export const MuiTheme: React.FC = props => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
);

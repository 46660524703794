import { useRouter } from 'next/router';
import Link, { LinkProps } from 'next/link';
import { cloneElement, ReactElement } from 'react';

export const ActiveLink: React.FC<LinkProps & { children: ReactElement; isActive?: boolean }> = ({
  children,
  ...props
}) => {
  const { asPath, pathname } = useRouter();
  const hrefPathName = typeof props.href === 'string' ? props.href : props.href?.pathname;
  const isActive =
    asPath.split('?')[0] === hrefPathName || pathname === hrefPathName || props.isActive;

  return (
    <Link {...props} passHref>
      {cloneElement(children, { isActive })}
    </Link>
  );
};

export const RTMP_LIVE_STREAM_STATUS_DISCONNECTED = (sessionId: string) => ({
  eventType: 'video-live_stream-disconnected',
  channelName: `live_stream-${sessionId}`,
});

export const RTMP_LIVE_STREAM_STATUS_ACTIVE = (sessionId: string) => ({
  eventType: 'video-live_stream-active',
  channelName: `live_stream-${sessionId}`,
});

export const RTMP_LIVE_STREAM_STATUS_IDLE = (sessionId: string) => ({
  eventType: 'video-live_stream-idle',
  channelName: `live_stream-${sessionId}`,
});

export const RTMP_LIVE_STREAM_STATUS_DISABLED = (sessionId: string) => ({
  eventType: 'video-live_stream-disabled',
  channelName: `live_stream-${sessionId}`,
});

export const RTMP_CHINA_STATUS_START = (sessionId: string) => ({
  eventType: 'rtmp-china-start',
  channelName: `rtmp-china-live-stream-${sessionId}`,
});

export const RTMP_CHINA_STATUS_END = (sessionId: string) => ({
  eventType: 'rtmp-china-end',
  channelName: `rtmp-china-live-stream-${sessionId}`,
});

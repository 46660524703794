import qs from 'qs';
import { ParsedUrlQueryInput } from 'querystring';

export type QueryParamType = Record<
  string,
  string | string[] | number | boolean | Date | undefined
>;

export const getQuery = (params: QueryParamType) =>
  qs.stringify(params, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseQuery = <T extends { [x: string]: any }>(query: T | ParsedUrlQueryInput) => {
  const result = {} as typeof query;

  Object.keys(query).forEach(key => {
    if (query[key] || query[key] === false) {
      result[key as keyof typeof result] = query[key];
    }
  });

  return result;
};

/* eslint-disable */
import * as React from 'react';
import Link from 'next/link';

export const getFileExtension = (fileName: string) =>
  fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);

export const getFileFromUrl = (url: string) =>
  url.replace(/\?.*/, '').slice(url.lastIndexOf('/') + 1);

export const isPosterPrivate = (poster?: MorressierPoster | null) =>
  poster &&
  // eslint-disable-next-line
  ((poster.hasOwnProperty('public_access_enabled') && !poster.public_access_enabled) ||
    poster.embargoed);

export const isPDF = (fileName: string) => getFileExtension(fileName.split('?')[0]) === 'pdf';

export const preloadPosterThumbnails = (thumbs: string[]) => {
  for (const thumb_url of thumbs) {
    new Image().src = thumb_url;
  }
};

export const isScrolledIntoView = (el?: HTMLElement | null) => {
  if (el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    return isVisible;
  }

  return false;
};

export const renderLink = (params: {
  discoveryProps?: {};
  contentProps?: {};
  newTab?: boolean;
  href: string;
  children: React.ReactNode;
}) =>
  params.discoveryProps ? (
    <Link
      href={params.href}
      key={params.href}
      passHref
      // @ts-ignore
      target={params.newTab ? '_blank' : ''}
      rel={params.newTab ? 'noreferrer' : ''}
      {...params.discoveryProps}
    >
      {params.children}
    </Link>
  ) : (
    <a
      key={params.href}
      href={params.href}
      target={params.newTab ? '_blank' : ''}
      rel={params.newTab ? 'noreferrer' : ''}
      style={{
        textDecoration: 'none',
      }}
    >
      {params.children}
    </a>
  );

export const countWords = (text?: string) =>
  text?.split(/\s+/).filter(str => str !== '').length ?? 0;

export const windowScrollTop = () =>
  window &&
  typeof window === 'object' &&
  window.scrollTo &&
  typeof window.scrollTo === 'function' &&
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

export const useBeforeLeaving = (
  shouldBlock: () => boolean,
  prompt = "If you close this screen, any information you've added to this submission will be lost",
) => {
  React.useEffect(() => {
    if (shouldBlock()) {
      const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault();

        e.returnValue = prompt;
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  });
};

export const useEventListener = <K extends keyof WindowEventMap>(
  eventType: K,
  handler: (event: WindowEventMap[K]) => void,
) => {
  const handlerRef = React.useRef<(e: any) => any>();

  // Swap reference to the handler on every render
  React.useEffect(() => {
    handlerRef.current = handler;
  });

  // Call internal reference of the handler instead of the external
  // so that we don't have to reattach listener on each `handler` reference change
  React.useEffect(() => {
    const internalHandler = (e: WindowEventMap[K]) => handlerRef.current!(e);

    window.addEventListener(eventType, internalHandler);

    return () => window.removeEventListener(eventType, internalHandler);
  }, [eventType]);
};

export const removeNullAndUndefined: any = (obj: any) =>
  Object.keys(obj)
    .filter(key => !(obj[key] === null || obj[key] === undefined))
    .reduce((acc, curr) => ({ ...acc, [curr]: obj[curr] }), {});

// Like Boolean(), but correctly infers resulting type
type FalsyType = false | null | undefined | '' | 0;
export function typedBoolean<ValueType>(value: ValueType): value is Exclude<ValueType, FalsyType> {
  return Boolean(value);
}

export function isValidURL(str: string) {
  let url;
  try {
    url = new URL(str);
  } catch (err) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const isCustomDomain = () => {
  return !(
    origin.startsWith('http://localhost') ||
    ['https://www.morressier.com', 'https://next.morressier.com'].includes(origin)
  );
};

import qs from 'qs';

import httpService from 'services/httpService';

import { parseQuery } from './utils';

export const getNominatedAbstract = (payload: { authorId?: string }) =>
  httpService
    .get<MorressierPaper[]>({
      url: `api/v3/discovery/papers/nominated-papers?${qs.stringify(
        parseQuery({
          authors: payload.authorId,
        }),
      )}`,
    })
    .then(({ data }) => data);

export const getOpenSubmissionCount = (payload: { authorId: string; timezoneOffset: number }) =>
  httpService
    .get<{
      submission_open_papers: number;
      submission_open_abstracts: number;
      submission_open_nominated_abstracts: number;
      submission_open_posters: number;
    }>({
      url: `api/v4/discovery/submissions/open-submission-count?${qs.stringify(
        parseQuery({
          authors: payload.authorId,
          timezoneOffset: payload.timezoneOffset,
        }),
      )}`,
    })
    .then(({ data }) => data)
    .catch(e => ({
      submission_open_papers: 0,
      submission_open_abstracts: 0,
      submission_open_nominated_abstracts: 0,
      submission_open_posters: 0,
    }));

import httpService from 'services/httpService';
import { getQuery } from './utils';

const BASE_URL = '/api/notifications/v1';

export type NotificationResponse = {
  collection: {
    items: string[];
    limit: number;
    offset: number;
    total: number;
    unread: number;
    unseen: number;
  };
  notifications: MorressierNotification[];
};

export const fetchNotifications = (payload: { offset: number; limit: number }) =>
  httpService
    .get<NotificationResponse>({
      url: `${BASE_URL}/${getQuery({
        offset: payload.offset,
        limit: payload.limit,
      })}`,
    })
    .then(response => response.data);

export const markAllNotificationsRead = (): Promise<any> =>
  httpService.put({ url: `${BASE_URL}/read` }).then(response => response.data);

export const markAllNotificationsSeen = (): Promise<any> =>
  httpService.put({ url: `${BASE_URL}/seen` }).then(response => response.data);

export const markSingleNotificationRead = (id: string) =>
  httpService.put<MorressierNotification>({ url: `${BASE_URL}/read/${id}` }).then(response => ({
    notifications: [response.data],
  }));

import httpService from 'services/httpService';
import getConfig from 'next/config';
import { logger } from 'utils/logger';

export const setAuthInfo = (authInfo: MorressierAuthInfo) => {
  httpService.setAuthHeader(authInfo.access_token);
  localStorage.setItem('ajs_user_id', authInfo?.user_id);

  return {
    type: 'setAuthInfo',
    payload: authInfo,
  } as const;
};

export const setAuthUser = (data: MorressierUser) =>
  ({
    type: 'setAuthUser',
    payload: data,
  } as const);

export const setProfile = (data: MorressierUserProfile) =>
  ({
    type: 'setProfile',
    payload: data,
  } as const);

export const setIsLoadingProfile = (isLoadingProfile: boolean) =>
  ({
    type: 'setIsLoadingProfile',
    payload: isLoadingProfile,
  } as const);

export const setAuthInit = (props?: { error: any }) =>
  ({
    type: 'setAuthInit',
    payload: props?.error,
  } as const);

export const setUserEvents = (events: UserEvent[]) =>
  ({
    type: 'setUserEvents',
    payload: events,
  } as const);

export const setIsLoadingUserEvents = (isLoadingUserEvents: boolean) =>
  ({
    type: 'setIsLoadingUserEvents',
    payload: isLoadingUserEvents,
  } as const);

export const logOut = () => {
  const nextConfig = getConfig();
  const DOMAIN = nextConfig.publicRuntimeConfig.api_base;

  httpService
    .get({ url: `${DOMAIN}/session/logout` })
    .then(() => httpService.removeAuthHeader())
    .catch(e => logger.error(e));

  return {
    type: 'logOut',
  } as const;
};

export const setSubmissionOpen = (data: number) =>
  ({
    type: 'setSubmissionOpen',
    payload: data,
  } as const);

export type AuthActions =
  | ReturnType<typeof setAuthInfo>
  | ReturnType<typeof setAuthUser>
  | ReturnType<typeof setProfile>
  | ReturnType<typeof setAuthInit>
  | ReturnType<typeof setIsLoadingProfile>
  | ReturnType<typeof setIsLoadingUserEvents>
  | ReturnType<typeof setUserEvents>
  | ReturnType<typeof setSubmissionOpen>
  | ReturnType<typeof logOut>;

import { IconType } from 'icons';

const getProps = ({ size, ...props }: IconType) => ({
  width: `${size}rem`,
  height: `${size}rem`,
  ...props,
});

const calendars = {
  google: (props: IconType) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="186 38 76 76" {...props}>
      <path fill="#fff" d="M244 56h-40v40h40V56z" />
      <path fill="#EA4335" d="M244 114l18-18h-18v18z" />
      <path fill="#FBBC04" d="M262 56h-18v40h18V56z" />
      <path fill="#34A853" d="M244 96h-40v18h40V96z" />
      <path fill="#188038" d="M186 96v12c0 3.315 2.685 6 6 6h12V96h-18z" />
      <path fill="#1967D2" d="M262 56V44c0-3.315-2.685-6-6-6h-12v18h18z" />
      <path fill="#4285F4" d="M244 38h-52c-3.315 0 -6 2.685-6 6v52h18V56h40V38z" />
      <path
        fill="#4285F4"
        d="M212.205 87.03c-1.495-1.01-2.53-2.485-3.095-4.435l3.47-1.43c.315 1.2.865 2.13 1.65 2.79.78.66 1.73.985 2.84.985 1.135 0 2.11-.345 2.925-1.035s1.225-1.57 1.225-2.635c0-1.09-.43-1.98-1.29-2.67-.86-.69-1.94-1.035-3.23-1.035h-2.005V74.13h1.8c1.11 0 2.045-.3 2.805-.9.76-.6 1.14-1.42 1.14-2.465 0 -.93-.34-1.67-1.02-2.225-.68-.555-1.54-.835-2.585-.835-1.02 0 -1.83.27-2.43.815a4.784 4.784 0 0 0 -1.31 2.005l-3.435-1.43c.455-1.29 1.29-2.43 2.515-3.415 1.225-.985 2.79-1.48 4.69-1.48 1.405 0 2.67.27 3.79.815 1.12.545 2 1.3 2.635 2.26.635.965.95 2.045.95 3.245 0 1.225-.295 2.26-.885 3.11-.59.85-1.315 1.5-2.175 1.955v.205a6.605 6.605 0 0 1 2.79 2.175c.725.975 1.09 2.14 1.09 3.5 0 1.36-.345 2.575-1.035 3.64s-1.645 1.905-2.855 2.515c-1.215.61-2.58.92-4.095.92-1.755.005-3.375-.5-4.87-1.51zM233.52 69.81l-3.81 2.755-1.905-2.89 6.835-4.93h2.62V88h-3.74V69.81z"
      />
    </svg>
  ),
  outlook: (props: IconType) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="48px"
      height="48px"
      {...props}
    >
      <path
        fill="#1976d2"
        d="M28,13h14.533C43.343,13,44,13.657,44,14.467v19.066C44,34.343,43.343,35,42.533,35H28V13z"
      />
      <rect width="14" height="15.542" x="28" y="17.958" fill="#fff" />
      <polygon fill="#1976d2" points="27,44 4,39.5 4,8.5 27,4" />
      <path
        fill="#fff"
        d="M15.25,16.5c-3.176,0-5.75,3.358-5.75,7.5s2.574,7.5,5.75,7.5S21,28.142,21,24	S18.426,16.5,15.25,16.5z M15,28.5c-1.657,0-3-2.015-3-4.5s1.343-4.5,3-4.5s3,2.015,3,4.5S16.657,28.5,15,28.5z"
      />
      <rect width="2.7" height="2.9" x="28.047" y="29.737" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="31.448" y="29.737" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="34.849" y="29.737" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="28.047" y="26.159" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="31.448" y="26.159" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="34.849" y="26.159" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="38.25" y="26.159" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="28.047" y="22.706" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="31.448" y="22.706" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="34.849" y="22.706" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="38.25" y="22.706" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="31.448" y="19.112" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="34.849" y="19.112" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="38.25" y="19.112" fill="#1976d2" />
    </svg>
  ),
  default: (props: IconType) => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(0 -1028.4)">
        <path
          d="m5 1032.4c-1.1046 0-2 0.9-2 2v14c0 1.1 0.8954 2 2 2h6 2 6c1.105 0 2-0.9 2-2v-14c0-1.1-0.895-2-2-2h-6-2-6z"
          fill="#bdc3c7"
        />
        <path
          d="m5 3c-1.1046 0-2 0.8954-2 2v14c0 1.105 0.8954 2 2 2h6 2 6c1.105 0 2-0.895 2-2v-14c0-1.1046-0.895-2-2-2h-6-2-6z"
          fill="#ecf0f1"
          transform="translate(0 1028.4)"
        />
        <path d="m5 1031.4c-1.1046 0-2 0.9-2 2v3h18v-3c0-1.1-0.895-2-2-2h-6-2-6z" fill="#e74c3c" />
        <path
          d="m7 5.5a1.5 1.5 0 1 1 -3 0 1.5 1.5 0 1 1 3 0z"
          fill="#c0392b"
          transform="translate(.5 1028.4)"
        />
        <path
          d="m6 1c-0.5523 0-1 0.4477-1 1v3c0 0.5523 0.4477 1 1 1s1-0.4477 1-1v-3c0-0.5523-0.4477-1-1-1z"
          fill="#bdc3c7"
          transform="translate(0 1028.4)"
        />
        <path
          d="m7 5.5a1.5 1.5 0 1 1 -3 0 1.5 1.5 0 1 1 3 0z"
          fill="#c0392b"
          transform="translate(12.5 1028.4)"
        />
        <g fill="#bdc3c7">
          <path d="m18 1029.4c-0.552 0-1 0.4-1 1v3c0 0.5 0.448 1 1 1s1-0.5 1-1v-3c0-0.6-0.448-1-1-1z" />
          <path d="m5 1039.4v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2z" />
          <path d="m5 1042.4v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2z" />
          <path d="m5 1045.4v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2z" />
        </g>
        <rect fill="#c0392b" height="1" transform="translate(0 1028.4)" width="18" x="3" y="8" />
      </g>
    </svg>
  ),
};

interface CalendarIconProps extends IconType {
  type: keyof typeof calendars;
}

export const CalendarIcon: React.FC<CalendarIconProps> = ({ type, ...props }) => {
  const Icon = calendars[type];
  return <Icon className="calendar-icon" {...getProps(props)} />;
};

CalendarIcon.defaultProps = {
  color: 'currentColor',
  type: 'default',
  size: 1.5,
};

import { useEffect, useState } from 'react';

export const useLocation = () => {
  const [location, setLocation] = useState<typeof window.location>();

  useEffect(() => {
    setLocation(window.location);
  }, []);

  return location;
};

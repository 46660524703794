import { color, SimpleText, Button, media } from '@morressier/ts';
import { breakpoints } from 'components/ThemedPage/muiTheme';
import styled, { css, ThemeProvider } from 'styled-components';
import { Theme } from 'components/ThemedPage';

export const PageContainer = styled.div<{ withTopMargin?: boolean }>`
  max-width: 1280px;
  padding: 0 1rem;
  position: relative;

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 0 2rem;
  }

  @media screen and (min-width: 1280px) {
    margin: 0 auto;
    min-width: 1280px;
  }

  ${props =>
    props.withTopMargin &&
    css`
      padding-top: 64px !important;
    `}
`;

export const LongPageContainer = styled.div`
  margin: auto;
  padding: 0 0.5rem;

  ${media.small`
        padding: 0 3rem ;
    `};
  ${media.large`
	    max-width: 852px;
    `};
  ${media.xlarge`
	    max-width: 1650px;
    `};
`;

export const Section = styled.section`
  margin-top: 72px;
`;

export const SectionContainer = styled(Section)`
  padding: 4rem 0;
  background-color: ${color('sidebarGrey')};
`;

// Wraps content and ensures the footer is at the bottom
export const MainWrapper = styled.div`
  // subtract topbar and footer heights
  min-height: calc(100vh - 64px - 74px);
  padding-bottom: 64px;
`;

const customTheme = (props: Partial<Theme>) => (theme: Theme) => ({
  ...theme,
  ...props,
  color: {
    ...theme.color,
    ...props?.color,
  },
});

export const ThemeOverides: React.FC<Partial<Theme>> = ({ children, ...props }) => (
  <ThemeProvider theme={customTheme(props)}>{children}</ThemeProvider>
);

export const PageTitle = styled(SimpleText).attrs(() => ({
  as: 'h1',
  size: 'h4_new',
  color: 'secondaryBrand',
  fontWeight: 'bold',
}))<{ secondLevel?: boolean }>`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 800;
  margin-bottom: 1rem;

  @media (min-width: 1024.1px) {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 800;
    ${props =>
      props.secondLevel &&
      css`
        font-size: 2rem;
        line-height: 2.5rem;
      `}
  }
`;

export const SubTitle = styled(SimpleText).attrs(() => ({
  as: 'h2',
  color: 'secondaryBrand',
  fontWeight: 'bold',
}))<{ withBottomPadding?: boolean }>`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;

  ${props =>
    props.withBottomPadding &&
    css`
      padding-bottom: 16px;
    `}
`;

export const Backbutton = styled(Button)`
  margin: 1rem 2rem;

  @media screen and (max-width: ${breakpoints.sm}px) {
    margin: 1rem;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 1rem 2rem;
  }
`;

import { setEventTabsConfig, useEventContext } from 'store';
import useSWR, { mutate } from 'swr';

export const useEventTabsConfig = (eventId?: string | string[] | null) => {
  const url = eventId ? `api/v3/discovery/events/${eventId}/tabs` : null;
  const { state, dispatch } = useEventContext();

  // no need to fetch tabs config info if we already have it in store
  const { data, error } = useSWR<MorressierEventTabsConfiguration>(state?.tabsConfig ? null : url);

  const filterData = () => {
    if (state?.tabsConfig) {
      return state.tabsConfig;
    }

    if (data) {
      dispatch(setEventTabsConfig(data));
      return data;
    }

    return data;
  };

  return {
    data: filterData(),
    refetch: () => mutate(url),
    loading: !data && !error,
  };
};

import { color, Flexbox } from '@morressier/ts';
import styled, { css } from 'styled-components';

export const LinksContainer = styled.div`
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 1280px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    justify-content: space-between;
    box-shadow: 0 -0px 1px 1px rgba(0, 0, 0, 0.1);
  }
`;

export const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 1rem;
`;

export const Navbar = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: white;
  justify-content: space-between;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
`;

export const LinkItem = styled.a<{
  isActive?: boolean;
  showDesktopIcon?: boolean;
}>`
  padding: 0 8px;
  position: relative;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  font-weight: 600;
  font-size: 24px;
  color: ${color('secondaryBrand')};
  text-decoration: none;

  ${props =>
    props.isActive &&
    css`
      &::after {
        left: 0;
        bottom: 0;
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        background-color: ${color('primaryBrand')};
      }
    `}

  ${props => css`
    svg {
      display: ${props.showDesktopIcon ? 'flex' : 'none'};
      width: 30px;
      height: 30px;
    }
  `}

  @media screen and (min-width: 1281px) {
    svg {
      display: ${props => (props.showDesktopIcon ? 'flex' : 'none')};
      margin-right ${props => (props.showDesktopIcon ? '0.5rem' : 0)};
    }
  }

  @media screen and (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
    font-size: 14px;

    svg {
      display: flex;
      margin-bottom: 0.25rem;  
    }
  }

  @media screen and (max-width: 700px) {
    padding: 16px;
  }

  @media screen and (max-width: 400px) {
    padding: 16px 4px;
  }
`;

export const IconContainer = styled.div`
  padding-right: 1rem;
  svg {
    color: #424d5d;
    cursor: pointer;
  }
`;

export const TopImage = styled.img<{ empty?: boolean }>`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  object-fit: cover;
  margin: 0 16px;
  align-self: center;
  cursor: pointer;
  border-radius: 3px;

  ${props =>
    props.empty &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${color('sidebarGrey')};
      color: ${color('grey')};
    `}
`;

export const TopImagePlaceholder = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background-color: ${color('lightGrey')};
  margin: 0 16px;
  align-self: center;
  cursor: pointer;
  border-radius: 3px;
`;

export const ProfileAvatar = styled.img`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  object-fit: cover;
  background-color: grey;
  border-radius: 50%;
  margin: 0 8px 0 0;
`;

export const BackButtonContainer = styled(Flexbox)`
  margin-left: 1em;
  max-width: 10.125rem;
  @media screen and (min-width: 700px) {
    max-width: 14.25rem;
  }
`;

export const MobileFlyout = styled.div`
  position: fixed;
  bottom: 80px;
  width: 100%;
  background-color: ${color('white')};
  box-shadow: 0 0px 1px 1px rgb(0 0 0 / 10%);
  padding-bottom: 1rem;
`;

export const FlyoutItem = styled.div`
  padding: 0 8px;
  position: relative;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  font-weight: 600;
  font-size: 16px;
  color: ${color('secondaryBrand')};
  text-decoration: none;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1.5rem;
    margin-left: 0.5rem;
  }
`;

export const CloseIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  color: ${color('secondaryBrand')};
`;

import axios, { AxiosRequestConfig } from 'axios';
import getConfig from 'next/config';
import { concatUrlWithParams, IParam } from 'utils/concatUrlWithParams';
import https from 'https';

const { publicRuntimeConfig } = getConfig();

export const axiosInstance = axios.create({
  baseURL: publicRuntimeConfig.api_base,
  timeout: 30000,
  httpsAgent: new https.Agent({ keepAlive: true }),
  withCredentials: true,
});

type BaseParams = { url: string; params?: IParam };
type IGet = { config?: AxiosRequestConfig } & BaseParams;
type IPost = { url: string; data?: unknown; config?: AxiosRequestConfig };

const get = <T = unknown>(args: IGet) =>
  axiosInstance.get<T>(concatUrlWithParams(args.url, args.params), args.config);

const post = <T = unknown>(args: IPost) => axiosInstance.post<T>(args.url, args.data, args.config);

const put = <T = unknown>(args: IPost) => axiosInstance.put<T>(args.url, args.data, args.config);

const patch = <T = unknown>(args: IPost) =>
  axiosInstance.patch<T>(args.url, args.data, args.config);

const deleteMethod = <T = unknown>(args: Omit<IPost, 'data'>) =>
  axiosInstance.delete<T>(args.url, args.config);

const setAuthHeader = (token: string, header = 'Authorization' as const) => {
  if (token) {
    axiosInstance.defaults.headers.common[header] = `Bearer ${token}`;
  }
};

const removeAuthHeader = (header = 'Authorization' as const) => {
  axiosInstance.defaults.headers.common[header] = '';
};

const httpService = {
  get,
  post,
  put,
  patch,
  delete: deleteMethod,
  setAuthHeader,
  removeAuthHeader,
};

export default httpService;

import { useEffect, useState } from 'react';
import getConfig from 'next/config';
import { useAuthContext } from 'store';
import { identify } from 'utils/eventTracking';
import { IMutableContext } from 'unleash-proxy-client';
import { logger } from '../logger';
import unleashProxyClient from './unleashProxyClient';
import { A_B_TEST_FLAG_NAMES, A_B_TEST_FLAG_VALUES } from './flags';

const { publicRuntimeConfig } = getConfig();

const A_B_TEST_PREFIX = publicRuntimeConfig.A_B_TEST_PREFIX || '';

export const useFeatureFlag = (featureFlagName: string) => {
  const client = unleashProxyClient();
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (!client) return;
    client.on('update', () => {
      const enabled = client?.isEnabled(featureFlagName);
      setFlag(!!enabled);
    });

    client.on('ready', () => {
      const enabled = client?.isEnabled(featureFlagName);
      setFlag(!!enabled);
    });
  }, [client, featureFlagName]);

  return flag;
};

export const useUnleashed = (featureFlags: string[], context: IMutableContext) => {
  const [features, setFeatures] = useState<Record<string, boolean>>({});
  const unleash = unleashProxyClient();
  const userData = useAuthContext();

  useEffect(() => {
    if (unleash) {
      unleash.updateContext(context);
      unleash.start();

      const updateFeatureFlags = () => {
        const A_B_Test_Flags: any = {};
        const newFeatures = featureFlags.reduce(
          (acc: Record<string, boolean>, featureFlag: string) => {
            const isEnabled = unleash.isEnabled(featureFlag);
            acc[featureFlag] = isEnabled;

            if (A_B_TEST_FLAG_NAMES[featureFlag]) {
              const featureFlagKey = `${A_B_TEST_PREFIX}${A_B_TEST_FLAG_NAMES[featureFlag]}`;
              A_B_Test_Flags[featureFlagKey] = A_B_TEST_FLAG_VALUES[featureFlag][`${isEnabled}`];
            }

            return acc;
          },
          {},
        );

        if (userData.state?.profile) identify(userData.state?.profile, { ...A_B_Test_Flags });
        setFeatures(newFeatures);
      };

      unleash.on('ready', updateFeatureFlags);
      unleash.on('update', updateFeatureFlags);
    } else {
      logger.error('Unleash not initialized');
    }
    return () => {
      unleash?.stop();
    };
  }, []);

  return { unleash, features };
};

import React from 'react';
import { captureException } from '@sentry/nextjs';
import { logger } from 'utils/logger';
import { Text, Flexbox } from '@morressier/ts';

type Props = {
  children: React.ReactNode;
};
type State = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logger.error(error, errorInfo);
    captureException(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flexbox
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: 'calc(100vh - 64px - 64px - 74px)' }}
        >
          <div style={{ textAlign: 'center' }}>
            <Text size="h3_new">We're sorry, something's gone wrong.</Text>
            <Text size="h5_new">Our team has been notified, and we're working to fix this!</Text>
          </div>
        </Flexbox>
      );
    }

    return this.props.children;
  }
}
